/* Code Blocks */
.editor-preview code, .editor-preview pre, pre code {
  font-family: monospace, Mono, !important;
  font-size: 16px;
  background-color: #eeeeee;
  color: #8f0e00;
  padding: 2px 2px;
  border-radius: 4px;
  tab-size: 2 !important;
}

.editor-preview pre {
  padding: 0.5em;
  overflow-x: auto;
  background-color: #f6f8fa;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
}

.editor-preview pre code {
  color: #24292f;
  background-color: transparent;
}

/* Markdown Code Copy */
.markdown-it-code-copy,
.show-markdown .markdown-it-code-copy {
  transition: all 0.3s;
  opacity: 0;
}

div:hover > .markdown-it-code-copy,
.show-markdown div:hover > .markdown-it-code-copy {
  opacity: 0.8;
}

.editor-preview .named-fence-block {
  position: relative;
  padding-top: 1.7em !important;
}

.editor-preview .named-fence-filename {
  display: block;
  margin-bottom: 0.5em !important;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 8px;
  font-weight: bold;
  text-align: center;
  color: var(--hue2);
  background: #dce0e5;
  opacity: 0.8;
}
.editor-preview {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    color: #24292f;
    background-color: #ffffff;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  .editor-preview .octicon {
    display: inline-block;
    fill: currentColor;
    vertical-align: text-bottom;
  }
  .editor-preview h1:hover .anchor .octicon-link:before,
  .editor-preview h2:hover .anchor .octicon-link:before,
  .editor-preview h3:hover .anchor .octicon-link:before,
  .editor-preview h4:hover .anchor .octicon-link:before,
  .editor-preview h5:hover .anchor .octicon-link:before,
  .editor-preview h6:hover .anchor .octicon-link:before {
    width: 16px;
    height: 16px;
    content: ' ';
    display: inline-block;
    background-color: currentColor;
    -webkit-mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
  }
  
  .editor-preview details,
  .editor-preview figcaption,
  .editor-preview figure {
    display: block;
  }
  
  .editor-preview summary {
    display: list-item;
  }
  
  .editor-preview [hidden] {
    display: none !important;
  }
  
  .editor-preview a {
    background-color: transparent;
    color: #0969da;
    text-decoration: none;
  }
  
  .editor-preview a:active,
  .editor-preview a:hover {
    outline-width: 0;
  }
  
  .editor-preview abbr[title] {
    border-bottom: none;
    text-decoration: underline dotted;
  }
  
  .editor-preview b,
  .editor-preview strong {
    font-weight: 600;
  }
  
  .editor-preview dfn {
    font-style: italic;
  }
  
  .editor-preview h1 {
    margin-top: 24px;
    font-weight: 600;
    font-size: 2em;
    border-bottom: 1px solid hsla(210,18%,87%,1);
  }
  
  .editor-preview mark {
    background-color: #fff8c5;
    color: #24292f;
  }
  
  .editor-preview small {
    font-size: 90%;
  }
  
  .editor-preview sub,
  .editor-preview sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  
  .editor-preview sub {
    bottom: -0.25em;
  }
  
  .editor-preview sup {
    top: -0.5em;
  }
  
  .editor-preview img {
    border-style: none;
    max-width: 100%;
    box-sizing: content-box;
    background-color: #ffffff;
  }
  
  .editor-preview code,
  .editor-preview kbd,
  .editor-preview pre,
  .editor-preview samp {
    font-family: monospace,monospace;
    font-size: 1em;
  }
  
  .editor-preview figure {
    /* margin: 1em 40px; */
  }
  
  .editor-preview hr {
    box-sizing: content-box;
    overflow: hidden;
    background: transparent;
    border-bottom: 1px solid hsla(210,18%,87%,1);
    height: .25em;
    padding: 0;
    margin: 24px 0;
    background-color: #d0d7de;
    border: 0;
  }
  
  .editor-preview input {
    font: inherit;
    margin: 0;
    overflow: visible;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  .editor-preview [type=button],
  .editor-preview [type=reset],
  .editor-preview [type=submit] {
    -webkit-appearance: button;
  }
  
  .editor-preview [type=button]::-moz-focus-inner,
  .editor-preview [type=reset]::-moz-focus-inner,
  .editor-preview [type=submit]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  
  .editor-preview [type=button]:-moz-focusring,
  .editor-preview [type=reset]:-moz-focusring,
  .editor-preview [type=submit]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  
  .editor-preview [type=checkbox],
  .editor-preview [type=radio] {
    box-sizing: border-box;
    padding: 0;
  }
  
  .editor-preview [type=number]::-webkit-inner-spin-button,
  .editor-preview [type=number]::-webkit-outer-spin-button {
    height: auto;
  }
  
  .editor-preview [type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  
  .editor-preview [type=search]::-webkit-search-cancel-button,
  .editor-preview [type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  .editor-preview ::-webkit-input-placeholder {
    color: inherit;
    opacity: .54;
  }
  
  .editor-preview ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  
  .editor-preview a:hover {
    text-decoration: underline;
  }
  
  .editor-preview hr::before {
    display: table;
    content: "";
  }
  
  .editor-preview hr::after {
    display: table;
    clear: both;
    content: "";
  }
  
  .editor-preview table {
    border-spacing: 0;
    border-collapse: collapse;
    display: block;
    width: max-content;
    max-width: 100%;
    overflow: auto;
  }
  
  .editor-preview td,
  .editor-preview th {
    padding: 0;
  }
  
  .editor-preview details summary {
    cursor: pointer;
  }
  
  .editor-preview details:not([open])>*:not(summary) {
    display: none !important;
  }
  
  .editor-preview kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 11px ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
    line-height: 10px;
    color: #24292f;
    vertical-align: middle;
    background-color: #f6f8fa;
    border: solid 1px rgba(175,184,193,0.2);
    border-bottom-color: rgba(175,184,193,0.2);
    border-radius: 6px;
    box-shadow: inset 0 -1px 0 rgba(175,184,193,0.2);
  }
  
  .editor-preview h1,
  .editor-preview h2,
  .editor-preview h3,
  .editor-preview h4,
  .editor-preview h5,
  .editor-preview h6 {
    /* margin-top: 24px; */
    margin-bottom: 0px;
    padding: 0;
    font-weight: 600;
    line-height: 1.25;
  }
  
  .editor-preview h2 {
    margin-top: 16px;
    font-weight: 600;
    font-size: 1.5em;
    border-bottom: 1px solid hsla(210,18%,87%,1);
  }
  
  .editor-preview h3 {
    margin-top: 13px;
    font-weight: 600;
    font-size: 1.25em;
  }
  
  .editor-preview h4 {
    margin-top: 10px;
    font-weight: 600;
    font-size: 1em;
  }
  
  .editor-preview h5 {
    margin-top: 7px;
    font-weight: 600;
    font-size: .875em;
  }
  
  .editor-preview h6 {
    margin-top: 4px;
    font-weight: 600;
    font-size: .85em;
    color: #57606a;
  }
  
  .editor-preview p {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .editor-preview blockquote {
    margin: 0;
    padding: 0 1em;
    color: #57606a;
    border-left: .25em solid #d0d7de;
  }
  
  .editor-preview ul,
  .editor-preview ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 2em;
  }
  
  .editor-preview ol ol,
  .editor-preview ul ol {
    list-style-type: lower-roman;
  }
  
  .editor-preview ul ul ol,
  .editor-preview ul ol ol,
  .editor-preview ol ul ol,
  .editor-preview ol ol ol {
    list-style-type: lower-alpha;
  }
  
  .editor-preview dd {
    margin-left: 0;
  }
  
  .editor-preview tt,
  .editor-preview code {
    font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
    font-size: 12px;
  }
  
  .editor-preview pre {
    margin-top: 0;
    margin-bottom: 0;
    font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
    font-size: 12px;
    word-wrap: normal;
  }
  
  .editor-preview .octicon {
    display: inline-block;
    overflow: visible !important;
    vertical-align: text-bottom;
    fill: currentColor;
  }
  
  .editor-preview ::placeholder {
    color: #6e7781;
    opacity: 1;
  }
  
  .editor-preview input::-webkit-outer-spin-button,
  .editor-preview input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
  }
  
  .editor-preview .pl-c {
    color: #6e7781;
  }
  
  .editor-preview .pl-c1,
  .editor-preview .pl-s .pl-v {
    color: #0550ae;
  }
  
  .editor-preview .pl-e,
  .editor-preview .pl-en {
    color: #8250df;
  }
  
  .editor-preview .pl-smi,
  .editor-preview .pl-s .pl-s1 {
    color: #24292f;
  }
  
  .editor-preview .pl-ent {
    color: #116329;
  }
  
  .editor-preview .pl-k {
    color: #cf222e;
  }
  
  .editor-preview .pl-s,
  .editor-preview .pl-pds,
  .editor-preview .pl-s .pl-pse .pl-s1,
  .editor-preview .pl-sr,
  .editor-preview .pl-sr .pl-cce,
  .editor-preview .pl-sr .pl-sre,
  .editor-preview .pl-sr .pl-sra {
    color: #0a3069;
  }
  
  .editor-preview .pl-v,
  .editor-preview .pl-smw {
    color: #953800;
  }
  
  .editor-preview .pl-bu {
    color: #82071e;
  }
  
  .editor-preview .pl-ii {
    color: #f6f8fa;
    background-color: #82071e;
  }
  
  .editor-preview .pl-c2 {
    color: #f6f8fa;
    background-color: #cf222e;
  }
  
  .editor-preview .pl-sr .pl-cce {
    font-weight: bold;
    color: #116329;
  }
  
  .editor-preview .pl-ml {
    color: #3b2300;
  }
  
  .editor-preview .pl-mh,
  .editor-preview .pl-mh .pl-en,
  .editor-preview .pl-ms {
    font-weight: bold;
    color: #0550ae;
  }
  
  .editor-preview .pl-mi {
    font-style: italic;
    color: #24292f;
  }
  
  .editor-preview .pl-mb {
    font-weight: bold;
    color: #24292f;
  }
  
  .editor-preview .pl-md {
    color: #82071e;
    background-color: #FFEBE9;
  }
  
  .editor-preview .pl-mi1 {
    color: #116329;
    background-color: #dafbe1;
  }
  
  .editor-preview .pl-mc {
    color: #953800;
    background-color: #ffd8b5;
  }
  
  .editor-preview .pl-mi2 {
    color: #eaeef2;
    background-color: #0550ae;
  }
  
  .editor-preview .pl-mdr {
    font-weight: bold;
    color: #8250df;
  }
  
  .editor-preview .pl-ba {
    color: #57606a;
  }
  
  .editor-preview .pl-sg {
    color: #8c959f;
  }
  
  .editor-preview .pl-corl {
    text-decoration: underline;
    color: #0a3069;
  }
  
  .editor-preview [data-catalyst] {
    display: block;
  }
  
  .editor-preview g-emoji {
    font-family: "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 1em;
    font-style: normal !important;
    font-weight: 400;
    line-height: 1;
    vertical-align: -0.075em;
  }
  
  .editor-preview g-emoji img {
    width: 1em;
    height: 1em;
  }
  
  .editor-preview::before {
    display: table;
    content: "";
  }
  
  .editor-preview::after {
    display: table;
    clear: both;
    content: "";
  }
  
  .editor-preview>*:first-child {
    margin-top: 0 !important;
  }
  
  .editor-preview>*:last-child {
    margin-bottom: 0 !important;
  }
  
  .editor-preview a:not([href]) {
    color: inherit;
    text-decoration: none;
  }
  
  .editor-preview .absent {
    color: #cf222e;
  }
  
  .editor-preview .anchor {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
    line-height: 1;
  }
  
  .editor-preview .anchor:focus {
    outline: none;
  }
  
  .editor-preview p,
  .editor-preview blockquote,
  .editor-preview ul,
  .editor-preview ol,
  .editor-preview dl,
  .editor-preview table,
  .editor-preview pre,
  .editor-preview details {
    margin-top: 0;
    margin-bottom: 16px;
  }
  
  .editor-preview blockquote>:first-child {
    margin-top: 0;
  }
  
  .editor-preview blockquote>:last-child {
    margin-bottom: 0;
  }
  
  .editor-preview sup>a::before {
    content: "[";
  }
  
  .editor-preview sup>a::after {
    content: "]";
  }
  
  .editor-preview h1 .octicon-link,
  .editor-preview h2 .octicon-link,
  .editor-preview h3 .octicon-link,
  .editor-preview h4 .octicon-link,
  .editor-preview h5 .octicon-link,
  .editor-preview h6 .octicon-link {
    color: #24292f;
    vertical-align: middle;
    visibility: hidden;
  }
  
  .editor-preview h1:hover .anchor,
  .editor-preview h2:hover .anchor,
  .editor-preview h3:hover .anchor,
  .editor-preview h4:hover .anchor,
  .editor-preview h5:hover .anchor,
  .editor-preview h6:hover .anchor {
    text-decoration: none;
  }
  
  .editor-preview h1:hover .anchor .octicon-link,
  .editor-preview h2:hover .anchor .octicon-link,
  .editor-preview h3:hover .anchor .octicon-link,
  .editor-preview h4:hover .anchor .octicon-link,
  .editor-preview h5:hover .anchor .octicon-link,
  .editor-preview h6:hover .anchor .octicon-link {
    visibility: visible;
  }
  
  .editor-preview h1 tt,
  .editor-preview h1 code,
  .editor-preview h2 tt,
  .editor-preview h2 code,
  .editor-preview h3 tt,
  .editor-preview h3 code,
  .editor-preview h4 tt,
  .editor-preview h4 code,
  .editor-preview h5 tt,
  .editor-preview h5 code,
  .editor-preview h6 tt,
  .editor-preview h6 code {
    padding: 0 .2em;
    font-size: inherit;
  }
  
  .editor-preview ul.no-list,
  .editor-preview ol.no-list {
    padding: 0;
    list-style-type: none;
  }
  
  .editor-preview ol[type="1"] {
    list-style-type: decimal;
  }
  
  .editor-preview ol[type=a] {
    list-style-type: lower-alpha;
  }
  
  .editor-preview ol[type=i] {
    list-style-type: lower-roman;
  }
  
  .editor-preview div>ol:not([type]) {
    list-style-type: decimal;
  }
  
  .editor-preview ul ul,
  .editor-preview ul ol,
  .editor-preview ol ol,
  .editor-preview ol ul {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .editor-preview li>p {
    margin-top: 16px;
  }
  
  .editor-preview li+li {
    margin-top: .25em;
  }
  
  .editor-preview dl {
    padding: 0;
  }
  
  .editor-preview dl dt {
    padding: 0;
    margin-top: 16px;
    font-size: 1em;
    font-style: italic;
    font-weight: 600;
  }
  
  .editor-preview dl dd {
    padding: 0 16px;
    margin-bottom: 16px;
  }
  
  .editor-preview table th {
    font-weight: 600;
  }
  
  .editor-preview table th,
  .editor-preview table td {
    padding: 6px 13px;
    border: 1px solid #d0d7de;
  }
  
  .editor-preview table tr {
    background-color: #ffffff;
    border-top: 1px solid hsla(210,18%,87%,1);
  }
  
  .editor-preview table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }
  
  .editor-preview table img {
    background-color: transparent;
  }
  
  .editor-preview img[align=right] {
    padding-left: 20px;
  }
  
  .editor-preview img[align=left] {
    padding-right: 20px;
  }
  
  .editor-preview .emoji {
    max-width: none;
    vertical-align: text-top;
    background-color: transparent;
  }
  
  .editor-preview span.frame {
    display: block;
    overflow: hidden;
  }
  
  .editor-preview span.frame>span {
    display: block;
    float: left;
    width: auto;
    padding: 7px;
    margin: 13px 0 0;
    overflow: hidden;
    border: 1px solid #d0d7de;
  }
  
  .editor-preview span.frame span img {
    display: block;
    float: left;
  }
  
  .editor-preview span.frame span span {
    display: block;
    padding: 5px 0 0;
    clear: both;
    color: #24292f;
  }
  
  .editor-preview span.align-center {
    display: block;
    overflow: hidden;
    clear: both;
  }
  
  .editor-preview span.align-center>span {
    display: block;
    margin: 13px auto 0;
    overflow: hidden;
    text-align: center;
  }
  
  .editor-preview span.align-center span img {
    margin: 0 auto;
    text-align: center;
  }
  
  .editor-preview span.align-right {
    display: block;
    overflow: hidden;
    clear: both;
  }
  
  .editor-preview span.align-right>span {
    display: block;
    margin: 13px 0 0;
    overflow: hidden;
    text-align: right;
  }
  
  .editor-preview span.align-right span img {
    margin: 0;
    text-align: right;
  }
  
  .editor-preview span.float-left {
    display: block;
    float: left;
    margin-right: 13px;
    overflow: hidden;
  }
  
  .editor-preview span.float-left span {
    margin: 13px 0 0;
  }
  
  .editor-preview span.float-right {
    display: block;
    float: right;
    margin-left: 13px;
    overflow: hidden;
  }
  
  .editor-preview span.float-right>span {
    display: block;
    margin: 13px auto 0;
    overflow: hidden;
    text-align: right;
  }
  
  .editor-preview code,
  .editor-preview tt {
    padding: .2em .4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(175,184,193,0.2);
    border-radius: 6px;
  }
  
  .editor-preview code br,
  .editor-preview tt br {
    display: none;
  }
  
  .editor-preview del code {
    text-decoration: inherit;
  }
  
  .editor-preview pre code {
    font-size: 100%;
  }
  
  .editor-preview pre>code {
    padding: 0;
    margin: 0;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
  }
  
  .editor-preview .highlight {
    margin-bottom: 16px;
  }
  
  .editor-preview .highlight pre {
    margin-bottom: 0;
    word-break: normal;
  }
  
  .editor-preview .highlight pre,
  .editor-preview pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 6px;
  }
  
  .editor-preview pre code,
  .editor-preview pre tt {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
  }
  
  .editor-preview .csv-data td,
  .editor-preview .csv-data th {
    padding: 5px;
    overflow: hidden;
    font-size: 12px;
    line-height: 1;
    text-align: left;
    white-space: nowrap;
  }
  
  .editor-preview .csv-data .blob-num {
    padding: 10px 8px 9px;
    text-align: right;
    background: #ffffff;
    border: 0;
  }
  
  .editor-preview .csv-data tr {
    border-top: 0;
  }
  
  .editor-preview .csv-data th {
    font-weight: 600;
    background: #f6f8fa;
    border-top: 0;
  }
  
  .editor-preview .footnotes {
    font-size: 12px;
    color: #57606a;
    border-top: 1px solid #d0d7de;
  }
  
  .editor-preview .footnotes ol {
    padding-left: 16px;
  }
  
  .editor-preview .footnotes li {
    position: relative;
  }
  
  .editor-preview .footnotes li:target::before {
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -24px;
    pointer-events: none;
    content: "";
    border: 2px solid #0969da;
    border-radius: 6px;
  }
  
  .editor-preview .footnotes li:target {
    color: #24292f;
  }
  
  .editor-preview .footnotes .data-footnote-backref g-emoji {
    font-family: monospace;
  }
  
  .editor-preview .task-list-item {
    list-style-type: none;
  }
  
  .editor-preview .task-list-item label {
    font-weight: 400;
  }
  
  .editor-preview .task-list-item.enabled label {
    cursor: pointer;
  }
  
  .editor-preview .task-list-item+.task-list-item {
    margin-top: 3px;
  }
  
  .editor-preview .task-list-item .handle {
    display: none;
  }
  
  .editor-preview .task-list-item-checkbox {
    margin: 0 .2em .25em -1.6em;
    vertical-align: middle;
  }
  
  .editor-preview .contains-task-list:dir(rtl) .task-list-item-checkbox {
    margin: 0 -1.6em .25em .2em;
  }
  
  .editor-preview ::-webkit-calendar-picker-indicator {
    filter: invert(50%);
  }
  

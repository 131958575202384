/* tree */

.editor-preview .mdi-tree{
  margin-bottom: 10px;
}

.editor-preview .mdi-tree ul {
  padding-left: revert;
  margin-left: 10px;
}

.editor-preview .mdi-tree li {
  margin: 0px 0;
  list-style-type: none;
  position: relative;
  padding: 4px 5px 0px 5px;
}

.editor-preview .mdi-tree li::before {
  content: '';
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  right: auto;
  left: -20px;
  border-left: 1px solid #9f9f9f;
  bottom: 50px;
  border-width: 2px;
}

.editor-preview .mdi-tree li::after {
  content: '';
  position: absolute;
  top: 20px;
  width: 25px;
  height: 20px;
  right: auto;
  left: -20px;
  border-top: 1px solid #9f9f9f;
  border-width: 2px;
}

.editor-preview .mdi-tree li span {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 2px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 16px;
  border-radius: 5px;
}

.editor-preview .mdi-tree li:last-child::before {
  height: 20px;
}

.editor-preview .mdi-tree li .mdi-tree-node:hover {
  color: #787878;
  border: 1px solid #a5a5a5;
}

.editor-preview .mdi-tree li .mdi-tree-node:hover + ul li span {
  color: #787878;
  border: 1px solid #a5a5a5;
}

.editor-preview .mdi-tree li .mdi-tree-node:hover + ul li::after {
  border-color: #a5a5a5;
}

.editor-preview .mdi-tree li .mdi-tree-node:hover + ul li::before {
  border-color: #a5a5a5;
}

.editor-preview .mdi-tree li .mdi-tree-node:hover + ul::before {
  border-color: #a5a5a5;
}

.editor-preview .mdi-tree li .mdi-tree-node:hover + ul ul::before {
  border-color: #a5a5a5;
}

.editor-preview .mdi-tree > ul {
  margin: 0;
  padding: 0;
}

.editor-preview .mdi-tree > ul > li {
  padding-top: 0;
}

.editor-preview .mdi-tree > ul > li::before {
  border: 0;
}

.editor-preview .mdi-tree > ul > li::after {
  border: 0;
}

.editor-preview .mdi-tree::before {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-popover {
  pointer-events: visible !important;
  z-index: 1 !important;
}

.ant-popover .ant-popover-inner-content {
  background-color: #f2f3f7;
  padding: 15px;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.simplebar-content {
  height: 100%;
}

/* Animate */
.fade-enter {
  opacity: 0.1;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity ease-in-out 600ms;
}

.fade-exit,
.fade-exit.fade-exit-active .fade-exit-done {
  display: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* table */

.markdown-body table{
  max-width: 100%;
  margin-bottom: 0px;
  border: 0px;
  overflow-wrap: break-word;
  width: 100% !important;
}

.markdown-body table > thead > tr > th,
table > thead > tr > th {
  position: relative;
  color: #000000d9;
  padding: 6px 13px;
  overflow-wrap: break-word;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  transition: all 0.3s;
}
.markdown-body table > tbody > tr > td,
table > tbody > tr > td {
  position: relative;
  padding: 6px 13px;
  overflow-wrap: break-word;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  transition: all 0.3s;
  line-height: 24px;
  display: table-cell;
}

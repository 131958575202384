/* labels */

.mdi-label {
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.mdi-label-text-light {
  color: white;
}

.mdi-label-text-dark {
  color: #332900;
}

.mdi-label.mdi-label-text-light::before {
  display: none;
}

.mdi-label.mdi-label-text-dark::before {
  display: none;
}

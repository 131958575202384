.markdown-guide-modal .markdown-guide-table .ant-table-content .ant-table-tbody .ant-table-cell{
    vertical-align: top;
}

.markdown-guide-modal .markdown-guide-table .ant-table-content .ant-table-tbody .ant-table-cell h2 {
    font-size: 1.8em;
    font-weight: bold;
    line-height: 1.25em;
    border-bottom: 0px;
    font-family: Helvetica, "Segoe UI", sans-serif;
    margin-top: 0em;
    margin-bottom: 0em;
}

.markdown-guide-modal .markdown-guide-table .ant-table-content .ant-table-tbody .ant-table-cell .editor-preview {
    overflow-y: hidden;
    border-bottom: none;
}

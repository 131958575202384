/* General Styles */
@media screen and (min-width: 992px) {
  .mardown-editor p img {
    max-width: 60%;
  }
}


.editor-view{
  border: 1px solid #e3e3e3;
}

.mermaid {
  text-align: center;
}

.mardown-editor p,
.editor-preview p {
  margin-bottom: 0.5em;
}

.editor-preview h1,
.editor-preview h2,
.editor-preview h3,
.editor-preview h4,
.editor-preview h5 {
  border: none !important;
  font-weight: bold;
  font-family: Helvetica, "Segoe UI", sans-serif;
  margin-top: 0em;
  margin-bottom: 0em;
}

.editor-preview h1 {
  font-size: 2em;
  line-height: 1.28em;
  border-bottom: 0px;
}

.editor-preview h2 {
  font-size: 1.8em;
  line-height: 1.25em;
  border-bottom: 0px;
}

.editor-preview h3 {
  font-size: 1.6em;
  line-height: 1.25em;
}

.editor-preview h4 {
  font-size: 1.4em;
  line-height: 1.25em;
}

.editor-preview h5 {
  font-size: 1.2em;
  line-height: 1.25em;
}

/* Images */
.mardown-editor p img {
  display: block;
  margin: 0 auto;
}

/* Lists */
.editor-preview ol {
  padding-left: 1.5em !important;
  margin-bottom: 6px;
  padding-top: 2px;
}

.editor-preview ol li ol{
  padding-left: 1.5em !important;
}

.editor-preview ul {
  padding-left: 1.5em !important;
  margin-bottom: 6px;
  padding-top: 2px;
}

.editor-preview ul li ul {
  padding-left: 1.5em !important;
}

/*
.editor-preview ul li {
  margin-bottom: 4px;
}
*/

/* Editor Preview Specific Styles */
.editor-preview {
  overflow-y: auto;
  overflow-x: unset;
  border: 1px solid #e3e3e3;
  padding: 5px 10px;
}

.editor-preview hr {
  height: 2px;
}

.editor-preview > *:first-child > *:first-child {
  margin-top: 0;
}

.editor-preview mark {
  background-color: #fff8c5;
  border-radius: 5px;
  font-weight: 600;
  color: var(--mono1);
  padding: 1px 4px;
}

/* Blockquotes */
blockquote,
.show-markdown blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}
/*
.markdown-body ol li div pre {
  padding: 10px !important;
}*/
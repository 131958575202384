.menu-item {
  font-size: 16px;
}

.menu-item:hover {
  color: white !important;
}

.note-alert {
  color: #397eed !important;
}

.tip-alert {
  color: #41b54d !important;
}

.important-alert {
  color: #a374ec !important;
}

.caution-alert {
  color: #ef5450 !important;
}

.warning-alert {
  color: #cb961f !important;
}

.note-alert:hover {
  background-color: #397eed !important;
}

.tip-alert:hover {
  background-color: #41b54d !important;
}

.important-alert:hover {
  background-color: #a374ec !important;
}

.caution-alert:hover {
  background-color: #ef5450 !important;
}

.warning-alert:hover {
  background-color: #cb961f !important;
}

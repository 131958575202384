.editor-preview figure {
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  width: fit-content !important;
}

.editor-preview figure figcaption {
  font-family: "Times New Roman", Times, serif;
  font-style: oblique;
  font-weight: 500;
  color: #333;
  background-color: white;
}

/* Code Tabs */
.code-tabs {
  border: 1px solid rgb(240, 240, 240);
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 16px;
}

.code-tabs pre,
.code-tabs input {
  display: none;
}

.code-tabs input:checked + div > pre {
  display: block;
}

.code-tabs label {
  color: rgba(191, 191, 191, 1);
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  padding: 2px 10px;
  margin: 5px;
  font-size: 14px;
  font-weight: 700;
}

.code-tabs input:checked + label {
  color: rgba(38, 38, 38, 1);
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
}

.code-tabs ul {
  font-size: 0;
  padding: 0;
  white-space: nowrap;
  overflow: auto;
  user-select: none;
  border-bottom: 1px solid rgb(240, 240, 240);
}

.code-tabs li {
  list-style: none;
  display: inline-block;
  position: relative;
  margin-bottom: 0 !important;
}

.code-tabs pre {
  background-color: #fff !important;
  border: none;
}